.NoPageFound {
  font-family: "Inter", sans-serif;
  color: #333;
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  user-select: none;
  margin: 0;
  padding: 0;
}

.NoPageFound p {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.homepage-link {
  color: #000000;
  text-decoration: underline;
  font-weight: normal;
}

.homepage-link:hover {
  color: #afacac;
}
