.home {
  padding: 150px 1px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  /* padding: 150px 20px; */
  box-sizing: border-box;
  user-select: none;
  font-family: "Inter", sans-serif;
}

.finder {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 730px;
  background-color: aqua;
  height: 100%;
  padding-top: 0px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  letter-spacing: -0.45px;
}

.header {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  padding-bottom: 15px;
}

.tabs-container {
  display: flex;
  gap: 7px;
  font-size: 14.5px;
  padding-bottom: 1px;
}

.tab {
  padding: 10px;
  padding-right: 5.5px;
  cursor: pointer;
  color: #888888;
  font-weight: normal;
}
.tab:hover {
  color: #afacac;
}

.tab.active {
  font-weight: 500;
  color: #000000;
}

.content {
  flex: 1;
  padding: 20px;
  padding-top: 0px;
  overflow-y: auto;
}

.folder-content {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: flex-start;
  height: 100%;
}

.folder-content h2 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.folder-content p {
  font-size: 14.5px;
  line-height: 1.5;
  color: #555;
  text-align: left;
  font-weight: 400;
}

.typewriter {
  position: relative;
  font-size: 14px;
  color: #000000;
  font-weight: normal;
  text-align: left;
  cursor: pointer;
  white-space: pre-wrap;
  margin: 0;
  line-height: 1.5;
  white-space: pre-line;
}

.cursor-blink {
  font-size: 14px;
  display: inline-block;
  position: relative;
  top: -1.3px;
  margin-left: 0px;
  color: #555;
  vertical-align: middle;
  animation: blink 1s infinite;
}

.blink {
  animation: blink 1s infinite;
}

@keyframes blink {
  0%,
  50% {
    opacity: 1;
  }
  50.01%,
  100% {
    opacity: 0;
  }
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.ttf") format("truetype");
  /* Add additional src lines for different font weights and styles if available */
  /* src: url('../fonts/Inter-Medium.ttf') format('truetype'); */
  /* src: url('../fonts/Inter-Bold.ttf') format('truetype'); */
  /* ... */
  font-weight: normal;
  font-style: normal;
}

.linkedin-text {
  font-size: 16px;
  color: blue;
  text-decoration: underline;
  margin: 2;
  cursor: pointer;
}

.linkedin-text:hover {
  color: #afacac !important;
}

.google-scholar-text {
  font-size: 16px;
  color: blue;
  text-decoration: underline;
  margin: 2;
  cursor: pointer;
}

.google-scholar-text:hover {
  color: #afacac !important;
}

.github-text {
  font-size: 16px;
  color: blue;
  text-decoration: underline;
  margin: 2;
  cursor: pointer;
}
.github-text:hover {
  color: #afacac !important;
}

.email-text {
  font-size: 16px;
  color: blue;
  text-decoration: underline;
  margin: 2;
  cursor: pointer;
}

.email-text:hover {
  color: #afacac !important;
}

.social-section {
  display: flex;
  gap: 17px;
  padding-top: 7px;
}

.cursor-blink.blink {
  animation: blink 1s infinite;
}

.project-desc {
  color: #888888 !important;
  font-size: 0.65em;
  font-size: 12.5px !important;
  margin: 1.5%;
  margin-right: auto;
  margin-top: 0%;
  margin-bottom: -0.35%;
  margin-left: 0%; /* adjust as needed */
  cursor: pointer;
}

.viewed-at {
  font-size: 14px;
  line-height: 1;
  color: #555;
  text-align: left;
  font-weight: 400;
  cursor: pointer;
  margin: 1.5% 0;
}

@media screen and (min-width: 1200px) {
  .tabs-container.active,
  .tabs-container .tabs-container:not(.active),
  .tabs-container {
    font-size: 15.5px;
    letter-spacing: -0.45px;
  }
  .folder-content p {
    font-size: 15.5px;
    line-height: 1.45;
    color: #555;
    text-align: left;
    letter-spacing: -0.45px;
    font-weight: 400;
  }
  .project-desc {
    font-size: 13.5px !important;
    margin: 1.5%;
    margin-right: auto;
    margin-top: 0%;
    margin-bottom: -0.35%;
    margin-left: 0%; /* adjust as needed */
    cursor: pointer;
  }

  .clickable-textt,
  .clickable-text {
    color: #555 !important;
    margin: 1.5%;
    font-size: 15.5px !important;
    margin-right: auto;
    margin-top: 0%;
    margin-bottom: 2.5%;
    margin-left: 0%; /* adjust as needed */
    cursor: pointer;
    flex: 1;
    background-color: rgb(255, 255, 255);
    overflow-y: auto;
  }
  .viewed-at {
    font-size: 15.5px !important;
  }
}

.typewriter.font-increased {
  font-size: 15.5px;
}

.clickable-textt,
.clickable-text {
  color: #555 !important;
  margin: 1.5%;
  font-size: 14px;
  margin-right: auto;
  margin-top: 0%;
  margin-bottom: 2.5%;
  margin-left: 0%; /* adjust as needed */
  cursor: pointer;
  flex: 1;
  background-color: rgb(255, 255, 255);
  overflow-y: auto;
}

.clickable-textt:hover,
.clickable-text:hover {
  color: #afacac !important;
}

.arrow-icon {
  margin-left: 3px !important;
  color: #888888 !important;
  vertical-align: middle;
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .project-desc {
    font-size: 7px !important;
  }

  .clickable-text {
    font-size: 8px !important;
  }
  .clickable-textt {
    font-size: 14px !important;
  }
  .clickable-textt:hover {
    color: #afacac !important;
  }
  .arrow-icon {
    font-size: 15px; /* adjust size as needed */
  }
}

.message {
  color: #888888 !important;
  font-size: 0.65em !important;
  font-size: 12px !important;
  margin: 7.5%;
  margin-right: auto;
  margin-top: 0%;
  margin-bottom: -0.5%;
  margin-left: 0%; /* adjust as needed */
  cursor: pointer;
}

.viewed-at .message {
  color: #333;
  padding-bottom: 8px; /* Adjust the padding value as needed */
}

.viewed-at .message a {
  color: inherit; /* This will inherit the color from its parent */
  cursor: pointer; /* Set the cursor to pointer to indicate it's clickable */
}

.resume-link {
  color: #555;
  text-decoration: none;
  transition: color 0.3s;
}

.resume-link:hover {
  color: #afacac;
}
